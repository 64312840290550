<template>
  <div>
    <header style="padding: 50px; border: 2px solid; background: lightgray">
      Header Part
    </header>

    <div>
      <iframe
        id="appointIframe"
        src="http://localhost:8080/appointment/64e7ec68-d81b-40de-81ed-a8b9bb82e08d"
        frameborder="0"
        style="height: 606px; width: 100%"
        scrolling="no"
      ></iframe>
    </div>

    <footer
      style="
        padding: 50px;
        padding-bottom: 350px;
        border: 2px solid;
        background: lightgray;
      "
    >
      Footer Part
    </footer>
  </div>
</template>

<script>
import iframeResizer from "iframe-resizer";

export default {
  data() {
    return {
      appointIframe: null,
      appointIframeHeight: 0,
    };
  },
  mounted() {
    this.resize();
  },
  methods: {
    resize() {
      console.log("resize s");
      this.appointIframe = iframeResizer.iframeResizer(
        {
          autoResize: false,
          // log: true,
          sizeWidth: true,
          sizeHeight: true,
          checkOrigin: false,
          // heightCalculationMethod: "grow",
          enablePublicMethods: true,
        },
        "#appointIframe"
      );
    },
  },
};
</script>

<style>
</style>